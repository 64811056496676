import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import * as styles from "../styles/main.module.scss"
import DarkmodeButton from "../darkmode"

const Layout = (props) => {
  return (
    <div className={styles.main}>
      <Helmet>
        <meta charSet="utf-8"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/devicon/2.10.1/devicon.min.css" />
      </Helmet>
      {props.heading}
      <div>
        <main style={props.heading && {marginTop:0}}>
          {props.children}
        </main>
        <footer>
          <div className="copyright">
            <hr></hr>
            &copy; 2025 Steven Kraft<br />
            <a href='mailto:steven.kraftjp@gmail.com'>steven.kraftjp@gmail.com</a>
          </div>
          <DarkmodeButton className="fixed" />
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.node
}

export default Layout
