import React from "react"
import CheatsheetCard from "../cheatsheet/cheatsheet-card.js"
import Grid from '@material-ui/core/Grid';
import { Link } from "gatsby"
import styled from "styled-components"
import Paper from '@material-ui/core/Card';
import Layout from "../layouts/main"
import {PatreonButton, PatreonCorner} from "../patreon"
import BackButton from "../backbutton"
import * as styles from "../styles/japanese.module.scss"
import SEO from '../seo'

const cheatsheets = require("../cheatsheet/cheatsheets.json")

const StyledPreviewCard = styled(Paper)`
  max-width: 850px;
  padding: 20px 40px;
  padding-bottom: 40px;
  margin: 20px auto;
  &.MuiPaper-root {
    background-color: var(--bgTable);
    color: var(--textNormal);
  }
  & > img {
    margin: 0;
  }
`

const PreviewCard = (props) => (
  <StyledPreviewCard>
    <h2>{props.title}</h2>
    <img src={props.img} alt={`${props.title} Cheatsheet`} />
  </StyledPreviewCard>
)

const CheatsheetGrid = (props) => (
  <>
    <h5>{props.title}</h5>
    <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={3}>
            {props.data.map(item => (
              <Grid key={item.img} item>
                <CheatsheetCard title={item.title} img={props.images[item.img].childImageSharp.fixed.src} link={item.img} earlyaccess={item.earlyaccess} />
              </Grid>
            ))}
          </Grid>
        </Grid>
    </Grid>
  </>
)

const Cheatsheet = (props) => (
  <Layout
    heading={(<div className={styles.banner3}>
      <h1 className={styles.heading} style={{marginBottom: 0}}>Japanese Grammar Cheatsheets</h1>
    </div>)}>
  <SEO
    title={`${props.pageContext.previewTitle ? props.pageContext.previewTitle + " - " : ""}Japanese Cheatsheets`}
    description="Collection of cheat sheet images for studying Japanese. Helpful information regarding Japanese conjugations, vocabulary, grammar points, and more!"
  />
  {props.pageContext.preview && <PreviewCard title={props.pageContext.previewTitle} img={props.pageContext.preview} />}
  {props.pageContext.category && <CheatsheetGrid title={props.pageContext.category} data={cheatsheets[props.pageContext.category]} images={props.pageContext.images} />}
  {!props.pageContext.category && Object.keys(cheatsheets).map(key => (
    <CheatsheetGrid key={key} title={key} data={cheatsheets[key]} images={props.pageContext.images} />
  ))}

  <br/><br/>
  <h2 style={{textAlign:"center"}}>🌸 More Coming Soon! 🌸</h2>
  <br/>
  <p><Link to="/projects/japanese/patreon">Patreon Supporters</Link></p>

  <p><PatreonButton /></p>
  <PatreonCorner />
  <BackButton />
  </Layout>
)

export default Cheatsheet
