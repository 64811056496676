import React from 'react';
import styled from "styled-components"
import { Link } from "gatsby"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Badge from '../badge'
import {FacebookShareButton, RedditShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, RedditIcon} from "react-share";

const StyledCard = styled(Card)`
  max-width: 250px;
  &.MuiPaper-root {
    background-color: var(--bgTable);
    color: var(--textNormal);
  }
  & > .MuiButtonBase-root .MuiCardMedia-img {
    object-position: top;
    margin-bottom: 0;
  }
  & > .MuiButtonBase-root > .MuiCardContent-root {
    padding: 8px;
  }
  & > .MuiCardActions-root {
    padding-top: 0;
    float: right;
    & > button {
      color: var(--linkNormal);
    }
  }
`

const EarlyAccessBadge = styled(Badge)`
  margin-left: 10px;
  position: absolute;
  font-size: 12px;
  padding: 3px 10px 5px 10px;
`

const CheatsheetCard = (props) => {
  const share = `Japanese ${props.title} Cheatsheet`
  const url = props.earlyaccess ? props.earlyaccess : `https://steven-kraft.com/projects/japanese/cheatsheets/${props.link}/`
  const linkComponent = props.earlyaccess ? 'a' : Link
  return (
    <StyledCard>
      <CardActionArea component={linkComponent} to={`/projects/japanese/cheatsheets/${props.link}`} href={props.earlyaccess}>
        <CardMedia
          component={"img"}
          alt={`${props.title} Cheatsheet`}
          height="225"
          src={props.img}
          title={props.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      { props.earlyaccess && <EarlyAccessBadge color="var(--patreon)">Early Access</EarlyAccessBadge> }
      <CardActions>
        <FacebookShareButton url={url} quote={share}><FacebookIcon size={24} round={true} /></FacebookShareButton>
        <TwitterShareButton url={url} title={share}><TwitterIcon size={24} round={true} /></TwitterShareButton>
        <RedditShareButton url={url} title={share}><RedditIcon size={24} round={true} /></RedditShareButton>
      </CardActions>
    </StyledCard>
  );
}

export default CheatsheetCard
