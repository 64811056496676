import React, { Component } from "react"
import * as styles from "./styles/patreon.module.scss"

class PatreonButton extends Component {
  render() {
    return (
        <a href="https://www.patreon.com/stevenkraft" className={styles.patreonButton}>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 3H3v18h3V3zm8.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13z"></path></svg>  
        Become a patron
        </a>
    )
  }
}

class PatreonCorner extends Component {
    render() {
      return (
        <svg width="80" height="80" viewBox="0 0 250 250" style={{position:"absolute", top: 0, right: 0}}>
        <a href="https://www.patreon.com/stevenkraft" className={styles.patreonCorner} aria-label="Become a Patron">
          <polygon points="0,0 250,0 250,250" fill="#e85b46" />
          <rect width="16.5" height="90" x="135" y="-115" fill="#052d49" transform="rotate(45)" />
          <circle cx="194" cy="81.25" r="33.75" fill="#fff" />
        </a>
        </svg>
      )
    }
}

export {PatreonButton, PatreonCorner};
