import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const StyledBadge = styled.span`
  font-size: 8px;
  background-color: var(--toggleHeading);
  color: white;
  border-radius: 25px;
  padding: 1px 5px;
  margin-left: 5px;
  font-weight: 600 !important;
  vertical-align: super;
  white-space: nowrap;
`

const Badge = (props) => (
  <StyledBadge style={{backgroundColor:props.color}} className={props.className}>
    {props.children}
  </StyledBadge>
)

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
};

export default Badge;
