import React from "react"
import { Link } from "gatsby"
import * as styles from "./styles/backbutton.module.scss"
import { Location } from '@reach/router'

const BackButton = (props) => (
  <Location>
    {({ location }) => {
      var path = location.pathname.substring(0, location.pathname.length - 2)
      var parentPath = path.substring(0, path.lastIndexOf("/")) + "/";
      return (
        <Link to={parentPath} className={styles.back} style={{color: props.color}}>&#8249;</Link>
      )
    }}
  </Location>
)

export default BackButton
